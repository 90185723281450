const config = {
  apiGateway: {
    REGION: 'us-west-2',
    INTERNAL_API_URL: 'https://9u8elhf6zd.execute-api.us-west-2.amazonaws.com/prod',
    COMPANY_API_URL: 'https://43c9o1igej.execute-api.us-west-2.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'taxonomy-company-1.10.2',
    HOSTNAME: 'https://company.staging.qberisktoesg.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.staging.qberisktoesg.forwoodsafety.com',
    WEBSOCKET: 'wss://vrob03hdgk.execute-api.us-west-2.amazonaws.com/staging'
  },
};

export default config;